import Link from 'components/Globals/Base/Link';
import Image from 'components/Image';
import { Feature } from 'interfaces/content/Ranking';
import RawHtml from 'utils/miscUtils/rawHtml';
import { EventData } from 'services/Gtm/entities';
import {
  FeatureContent,
  FeatureContentWrapper,
  FeatureImage,
  FeatureLabel,
  FeatureTitle,
  FeatureWrapper,
} from './FeatureContentModuleStyles';

interface FeatureContentModuleProps {
  feature: Feature;
  trackingData: EventData;
}

const FeatureContentModule = ({ feature, trackingData }: FeatureContentModuleProps) => {
  const { content, image, label, title, url } = feature;
  if (content === null || image === null || label === null || title === null || url === null) {
    return null;
  }
  return (
    <FeatureWrapper>
      <FeatureImage>
        <Link
          href={url}
          trackingData={{ ...trackingData, eventLabel: 'banner click : map' }}
        >
          <Image
            alt={title}
            src={image}
            width={1024}
            height={683}
          />
        </Link>
      </FeatureImage>
      <FeatureContentWrapper>
        <FeatureLabel>
          <Link
            href={url}
            trackingData={{ ...trackingData, eventLabel: `${label}` }}
            type='red'
          >
            {label}
          </Link>
        </FeatureLabel>
        <FeatureTitle>
          <Link
            href={url}
            type='brandGray'
            trackingData={{ ...trackingData, eventLabel: `title click : ${title}` }}
          >
            {title}
          </Link>
        </FeatureTitle>
        <FeatureContent>{RawHtml({ html: content })}</FeatureContent>
      </FeatureContentWrapper>
    </FeatureWrapper>
  );
};

export default FeatureContentModule;
