import styled from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import Heading from 'styles/typography/Heading';
import { arrowDown, arrowRight } from 'styles/icons';
import { Theme, getColor } from 'styles/theme';

export const Container = styled.div`
  padding-left: 16px;
  ul.open {
    a {
      display: inline-block;
      width: 100%;
    }
  };

  @media (${breakpoint.mdMin}) {
    padding-left: 0;
  };
`;

export const ArrowRight = styled.span<{ theme: Theme }>`
  border-color: ${getColor('iconPrimary')};
  ${arrowRight};
  margin: 0 0 6px 20px;
`;

export const Title = styled(Heading)<{ $color: string; $separator: string; theme: Theme }>`
  position: relative;
  border-left: ${(props) => (props.$separator === 'left' ? `2px solid ${props.$color}` : 'none')};
  padding-left: ${(props) => (props.$separator === 'left' ? '8px' : 'none')};

  &, button {
    font-weight: 600;
    color: ${getColor('textPrimary')};
    font-family: ${font.graphikCond};
    font-size: 32px;
    letter-spacing: -1.5px;
    line-height: 40px;
    text-align: left;
  }

  &.dropdown {
    display: inline-block;
    
    button {
      padding-right: 34px;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      right: 0;
      cursor: pointer;
    }

    &.open {
      .arrow {
        ${arrowDown}
        margin: 0 0 6px 20px;
      }
    }
  }

  @media (${breakpoint.mdMin}) {
    &, button {
      font-size: 60px;
      letter-spacing: -0.5px;
      line-height: 72px;
      padding-right: 24px;
      text-decoration-color: ${(props) => props.color};
      text-underline-offset: 16px;
      text-decoration-thickness: 2px;
      border-right: ${(props) => (props.$separator === 'leftRight' ? `2px solid ${props.$color}` : 'none')};
      border-left: ${(props) => props.$separator === 'leftRight' && 'none'};
      padding-left: ${(props) => props.$separator === 'leftRight' && '0'};
    }
  }
`;
