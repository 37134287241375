import Link from 'components/Globals/Base/Link';
import Image from 'components/Image';

interface StaticAdProps {
  alt: string;
  imageLink: string;
  url: string;
  isWideBanner: boolean;
}

const StaticAd = ({ alt, imageLink, url, isWideBanner }: StaticAdProps) => (
  <div className='static-ad'>
    <Link href={url}>
      <Image
        width={`${isWideBanner ? 728 : 300}`}
        height={`${isWideBanner ? 90 : 250}`}
        src={imageLink}
        alt={alt}
      />
    </Link>
  </div>
);

export default StaticAd;
