import { useRef, useState } from 'react';
import { MenuItemProps } from 'interfaces/navigation/Header';
import SubMenuItem from 'components/TopicBasedNavigation/SubmenuV1/SubMenuItem';
import useBreakpoint from 'hooks/useBreakpoint';
import useClickOutside from 'hooks/useClickOutside';
import { trackEvent } from 'services/Gtm/functions';
import { MenuItemSubUl } from 'components/TopicBasedNavigation/SubmenuV1/SubmenuStyles';
import * as S from './DropdownTitleStyles';

export interface Props {
  title: string;
  color: string;
  subMenu?: MenuItemProps[];
  className?: string;
  separatorPosition: string;
  dataCy?: string;
}
const DropdownTitle = ({ title, color, subMenu, className, separatorPosition, dataCy }: Props) => {
  const [showSubMenu, setShowSubmenu] = useState(false);
  const isMobile = useBreakpoint('mdMax');
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, () => setShowSubmenu(false));

  const buttonTrackingData = {
    eventAction: 'sub topic navigation toggle',
    eventCategory: 'navigation',
    eventLabel: showSubMenu ? 'close' : 'open',
  };

  const linkTrackingData = {
    contentPlacementCD: 'sub topic navigation',
    eventAction: 'sub topic navigation',
    eventCategory: 'navigation',
  };

  return (
    <S.Container
      ref={containerRef}
      className={className}
    >
      <S.Title
        as='h1'
        $fontFamily='graphikCond'
        $size={{ default: 'level2' }}
        className={`${showSubMenu ? 'open' : ''} ${subMenu && subMenu.length > 0 && isMobile ? 'dropdown' : 'title'}`}
        $color={color}
        $separator={separatorPosition}
        data-cy={dataCy}
      >
        {subMenu && subMenu.length > 0 && isMobile ? (
          <button
            type='button'
            onClick={() => {
              setShowSubmenu(!showSubMenu);
              if (trackEvent) trackEvent(buttonTrackingData);
            }}
          >
            {title}
            <S.ArrowRight className='arrow' />
          </button>
        ) : (
          title
        )}
      </S.Title>
      {showSubMenu && (
        <MenuItemSubUl className={`${showSubMenu ? 'open' : ''}`}>
          {subMenu?.map((menuItem: MenuItemProps) => (
            <SubMenuItem
              key={menuItem.label}
              {...menuItem}
              trackingData={{ ...linkTrackingData, eventLabel: `${menuItem.label}` }}
              level={2}
              parentLabel={title}
            />
          ))}
        </MenuItemSubUl>
      )}
    </S.Container>
  );
};

export default DropdownTitle;
