const RANKING_LIST_PAGE = {
  AD: 'ranking-list-page-ad',
  CALL_TO_ACTION: 'ranking-list-page-call-to-action',
  CALL_TO_ACTION_EXPLORE_LIST: 'ranking-list-page-call-to-action-explore-list',
  CAROUSEL: 'ranking-list-page-carousel',
  COVERAGE_MODULE: 'ranking-list-page-coverage-module',
  DESCRIPTION: 'ranking-list-page-description',
  MAP: 'ranking-list-page-map',
  TITLE: 'ranking-list-page-title',
  TOP_10: 'ranking-list-page-top10',
  VIDEO: 'ranking-list-page-video',
};

export type TestIds = {
  [key in keyof typeof RANKING_LIST_PAGE]?: string;
};

const getRankingListTestId = (value: keyof TestIds) => RANKING_LIST_PAGE[value];

export default getRankingListTestId;
