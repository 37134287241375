import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const FeatureWrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('surfaceBrand')};
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
  width: 100%;

  @media(${breakpoint.mdMin}) {
    flex-direction: row;
    height: 275px;
    margin-left: 0;
    margin-right: 48px;
  }
  @media(${breakpoint.xlMin}) {
    margin-left: 24px;
  }
`;

export const FeatureImage = styled.div`
  align-self: center;
  margin-top: 10px;
  width: 90%;
  @media(${breakpoint.mdMin}) {
    margin-left: 24px;
    margin-top: 0;
    width: 750px;
  }
  transition: 0.35s ease-in-out;
  &:hover {
    filter: brightness(80%);
    transition: 0.35s ease-in-out;
  }
`;

export const FeatureContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  @media(${breakpoint.mdMin}) {
    margin: 24px;
    padding: 0;
  }
  @media(${breakpoint.lgMin}) {
    padding: 0;
  }
`;

export const FeatureLabel = styled.div<{ theme: Theme }>`
  margin-bottom: 8px;
  a {
    font-family: ${font.graphikCond};
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    &:hover {
      color: ${getColor('textLink')};
    }
  }
`;

export const FeatureTitle = styled.div<{ theme: Theme }>`
  a {
    color: ${getColor('textPrimary')};
    font-family: ${font.saolText};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: .25px;
    line-height: 26.4px;
  }
`;

export const FeatureContent = styled.div<{ theme: Theme }>`
  font-family: ${font.graphikCond};
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  margin-bottom: 0;
  width: 100%;
  @media(${breakpoint.mdMin}) {
    margin-bottom: 16px;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: none;
      color: ${getColor('textPrimary')};
    }
  }
`;
