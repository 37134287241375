import { FranchiseListItemPreview } from 'interfaces/content/Ranking';
import { EventData } from 'services/Gtm/entities';
import Link from 'components/Globals/Base/Link';
import {
  FranchiseItemWrapper,
  FranchiseItemRank,
  FranchiseItemTitleWrapper,
  FranchiseItemTitle,
  FranchiseItemRevenue,
} from './RankingCompanyFranchiseItemStyles';

interface RankingCompanyFranchiseItem {
  franchiseItem: FranchiseListItemPreview;
  showRevenue: boolean;
  trackingData: EventData;
}

interface FranchiseItemData {
  [key: string]: string;
}

const RankingCompanyFranchiseItem = ({ franchiseItem, showRevenue, trackingData }: RankingCompanyFranchiseItem) => {
  const { data, order, rank, title, slug } = franchiseItem;
  const franchiseItemRevenue = (JSON.parse(data) as FranchiseItemData)['Revenues ($M)'];

  return (
    <FranchiseItemWrapper>
      <Link
        href={slug}
        trackingData={trackingData}
      >
        <FranchiseItemRank>{rank || order}</FranchiseItemRank>
        <FranchiseItemTitleWrapper>
          <FranchiseItemTitle>{title}</FranchiseItemTitle>
          {showRevenue && <FranchiseItemRevenue>{franchiseItemRevenue}</FranchiseItemRevenue>}
        </FranchiseItemTitleWrapper>
      </Link>
    </FranchiseItemWrapper>
  );
};

export default RankingCompanyFranchiseItem;
