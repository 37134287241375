import styled from 'styled-components';
import { color, font } from 'styles/globals';

export const FranchiseItemWrapper = styled.li`
  border-top: 1px solid ${color.secondaryLightGray};
  padding: 16px 24px;
  position: relative;
  width: 95%;
  a {
    align-items: center;
    display: flex;
    text-decoration: none;
    width: 100%;
    &:hover {
      span {
      color: ${color.linkBlue};
      }
    }
  }
`;

export const FranchiseItemTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledSpan = styled.span`
  color: ${color.primaryBlack};
  display: flex;
  font-family: ${font.graphikCond};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
  padding-left: 8px;
`;

export const FranchiseItemTitle = StyledSpan;

export const FranchiseItemRevenue = StyledSpan;

export const FranchiseItemRank = styled.span`
  color: ${color.darkGray};
  display: flex;
  font-family: ${font.graphikCond};
  font-size: 24px;
  font-weight: 600;
  width: 24px;
`;
